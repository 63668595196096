import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import TitleBar from "../components/TitleBar/TitleBar";
import { useDispatch, useSelector } from "react-redux";
import { contactEnquiry, contactVolunteerForm } from "../redux/actionCreator";
import { toast } from "react-toastify";
import i18n from "../i18n";
import { contact_details, contact_loader, volunteerform_loading } from "../redux/commonReducer";
import { t } from "i18next";
import { Link } from "react-router-dom";
import icon from "../assets/images/right_arrow_yellow.svg";

function VolunteerWithUs() {

    const ref = useRef()
    const fileInputRef = useRef();
    const dispatch = useDispatch()

    const loading = useSelector(volunteerform_loading)

    const [errors, setErrors] = useState({});
    const [data, setData] = useState({
        // name: 'Shabeeb',
        // last_name: "Ali",
        // email: 'shabeebali@test.com',
        // phone: '1234566789',
    });

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "image") {
            if (files?.length) {
                setData({
                    ...data,
                    [name]: files[0],
                });
            }
        } else {
            setData({
                ...data,
                [name]: value,
            });
        }

        setErrors({ ...errors, [name]: "" })

    };

    const validation = async () => {
        let isValid = true;
        let errors = {}

        if (!data?.name?.trim()) {
            errors.name = t('first name is required');
        }

        if (!data?.last_name?.trim()) {
            errors.last_name = t('last name is required');
        }

        if (!data?.image) {
            errors.image = t('cv is required');
        }

        if (!data?.agree) {
            errors.agree = t('you must agree to terms and condtions');
        }

        if (!data?.email?.trim()) {
            errors.email = t('Email is required');
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
            errors.email = t('Invalid email address');
        }
        if (!data?.phone?.trim()) {
            errors.phone = t('Phone Number is required');
        } else if (!/^\+?\d{9,13}$/.test(data.phone)) {
            errors.phone = t('valid number');
        }
        
        if(Object.keys(errors).length){
            isValid = false
        }

        setErrors(errors)
        return isValid
    }

    const handleSubmit = async () => {
        const isValidated = await validation()
        if (!isValidated) {
            return
        } else {
            console.log({ data })
            let formData = new FormData();
            formData.append('name', data?.name);
            formData.append('last_name', data?.last_name);
            formData.append('image', data?.image);
            formData.append('type', 'volunteer');
            formData.append('phone', data?.phone);
            formData.append('email', data?.email);

            dispatch(contactVolunteerForm(formData, (res) => {
                toast.success(res?.data?.message, {
                    position: "bottom-center",
                    autoClose: 3000
                })
                setData({
                    name: '',
                    last_name: "",
                    email: '',
                    phone: '',
                    agree: '',
                    image: ''
                });
            }))
        }
    }

    console.log({ data })

    return (
        <section className="volunteer_with_us">
            <Container>
                <TitleBar
                    title={t('volunteer with us')}
                    breadcrumbs={[
                        { name: `${t('home')}`, path: "/" },
                        { name: `${t('volunteer with us')}`, path: "/volunteer_with_us" },
                    ]}
                    noBtn
                />
                <Row className='align-items-center home__about mb-4'>
                    <Col md={12} lg={12}>
                        <div className='description'>
                            <p
                            // dangerouslySetInnerHTML={{ __html: langSwitch ? aboutData?.description : aboutData?.description_ar }}
                            >
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                                ea commodo consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                                mollit anim id est laborum.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <div className="form-group">
                            <input type="text" name="name" key={data} value={data?.name} className="form-control" placeholder={t('your first name')} onChange={handleInputChange} />
                            <p className="error">{errors?.name}</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="form-group">
                            <input type="text" name="last_name" key={data} value={data?.last_name} className="form-control" placeholder={t('your last name')} onChange={handleInputChange} />
                            <p className="error">{errors?.last_name}</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="form-group">
                            <input type="text" name="email" key={data} value={data?.email} className="form-control" placeholder={t('email')} onChange={handleInputChange} />
                            <p className="error">{errors?.email}</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="form-group position-relative">
                            <p className="position-absolute country__code">+971</p>
                            <input type="number" name="phone" key={data} value={data?.phone} className="form-control phone__number" placeholder={t('phone number')} onChange={handleInputChange} />
                            <p className="error position-absolute">{errors?.phone}</p>
                        </div>
                    </Col>
                    <Col md={12} className="position-relative">
                        <div className="form-group upload__cv" onClick={() => fileInputRef.current.click()}>
                            <input name="image" onChange={handleInputChange} multiple={false} ref={fileInputRef} type='file' hidden />
                            <div>
                                <img width={30} src={'./assets/img/upload-cloud.svg'} />
                                <label className="mx-3 upload_cv_text" onClick={() => {
                                    const fileUrl = data?.image && URL.createObjectURL(data?.image);
                                    data?.image && window.open(fileUrl, '_blank');
                                }
                                }>{data?.image ? data?.image?.name : t("upload cv")}</label>
                            </div>
                            <p className="supporting_documents">{t("supporting documents (PDF, JPG, PNG )")}</p>
                        </div>
                        <p className="error position-absolute">{errors?.image}</p>
                    </Col>
                </Row>
                <Row className="mt-4 mb-4">
                    <Col md={8} sm={12} className="position-relative">
                        <div className="d-flex align-items-start">
                            <Form.Check // prettier-ignore
                                type={"checkbox"}
                                id={`consent-checkbox`}
                                className={`consent-checkbox`}
                                label={``}
                                name="agree"
                                onChange={handleInputChange}
                                checked={data?.agree}
                            />
                            <div className="d-flex flex-column align-items-start mx-2">
                                <label htmlFor="consent-checkbox" className="consent-label-1 consent-label">
                                    {t("I consent to be contacted by Emirates Human Rights Association (EHRA) about volunteering.")}
                                </label>
                                <label htmlFor="consent-checkbox" className="consent-label">
                                    {t("I confirm that the information provided is true and accurate.")}
                                </label>
                            </div>
                        </div>

                        <p className="error position-absolute">{errors?.agree}</p>
                    </Col>
                    <Col md={4} sm={12} className="d-flex align-items-end justify-content-end">
                        <button disabled={loading} className="submit" onClick={handleSubmit}>
                            {t('submit')}
                            {!loading ? <img src={icon} alt="" width={35} className="mx-2" /> :
                                <Spinner className="mx-2" animation="border" width={20} size="sm" />}
                        </button>
                    </Col>
                </Row>
            </Container>
            <img src="../assets/img/volunteer.png" width={"100%"} />
        </section>
    );
}

export default VolunteerWithUs;
