"use client";

import { useState, useEffect, useRef } from "react";
import { ArrowLeft, ArrowRight, X } from "lucide-react";
import "./OtpVerification.scss";
import Countdown from "react-countdown";
import OTPInput from "react-otp-input";
import { Col, Row, Spinner } from "react-bootstrap";
import { reSendOTP, sendOTP, submitDonation, validateOtp } from "../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { final_submit_loader, resend_otp_loader, validate_otp_loader } from "../../redux/commonReducer";
import { t } from "i18next";

export default function OtpVerification({
  setStep,
  emailOtp,
  setEmailOtp,
  endEmailTime,
  setEndEmailTime,
  isEmailCompleted,
  setIsEmailCompleted,
  emailOtpChangeHandler,
  phoneOtp,
  setPhoneOtp,
  endPhoneTime,
  setEndPhoneTime,
  isPhoneCompleted,
  setIsPhoneCompleted,
  data,
}) {
  const dispatch = useDispatch();
  const validateLoading = useSelector(validate_otp_loader)
  const resendOtpLoader = useSelector(resend_otp_loader)
  const submitLoading = useSelector(final_submit_loader)

  const [verifiedEmailOtp, setVerifiedEmailOtp] = useState(false);
  const [verifiedPhoneOtp, setVerifiedPhoneOtp] = useState(false);
  const [resendType, setResendType] = useState("")
  const [submitType, setSubmitType] = useState("")

  const renderer = ({ minutes, seconds }) => {
    return (
      <span className="mt-1">
        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </span>
    );
  };

  const handleEmailComplete = () => {
    setIsEmailCompleted(true);
  };

  const handlePhoneComplete = () => {
    setIsPhoneCompleted(true);
  };

  const otpSendHandler = (type, cb) => {
    let raw = {
      // first_name: data?.first_name,
      // last_name: data?.last_name,
      // phone: data?.phone,
      email: type === "email" ? data?.email : undefined,
      phone: type === "phone" ? data?.phone : undefined,
      type: [type],
    };
    dispatch(
      reSendOTP(raw, () => {
        cb();
      })
    );
  };

  const verifyOtpChangeHandler = (type) => {
    setSubmitType(type)
    let raw = {
      otp: type == "email" ? emailOtp : phoneOtp,
      email: type === "email" ? data?.email : undefined,
      phone: type === "phone" ? data?.phone : undefined,
      type: [type],
    };
    dispatch(
      validateOtp(raw, () => {
        toast.success(
          `${type == "email" ? "Email" : "Phone"} OTP verified successfully`,
          {
            position: "bottom-center",
            autoClose: 3000,
          }
        );
        if (type == "email" || type[0] == "email") {
          setVerifiedEmailOtp(true);
          if (!data?.type.includes("phone")) setVerifiedPhoneOtp(true);
        } else {
          setVerifiedPhoneOtp(true);
          if (!data?.type.includes("email")) setVerifiedEmailOtp(true);
        }
      })
    );
  };

  const onSubmitHandler = () => {
    let raw = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      phone: data?.phone,
      email: data?.email,
      type: data?.type,
      amount: data?.amount,
      reason_donation: data?.reason_donation
    };
    dispatch(
      submitDonation(raw, () => {
        setStep(4);
      })
    );

  };

  return (
    <>
      <h2 className="otp-title">{t("verification")}</h2>
      {/* <div className="otp-section"> */}
      <Row>
        {data?.type.includes("email") ? (
          <Col md={6} sm={12}>
            <div>
              <h3 className="otp-section-title">{t("email otp")}</h3>
              {verifiedEmailOtp ? (
                <p className="verification__text">{t("email verification successful")}</p>
              ) : (
                <>
                  <p className="otp-section-subtitle">
                    {t("we have sent an otp to your email address")}
                  </p>
                  <div className="otp_inputs d-flex align-items-center">
                    <OTPInput
                      value={emailOtp}
                      onChange={(e) => setEmailOtp(e)}
                      numInputs={4}
                      className="otp"
                      inputType="text"
                      renderInput={(props) => <input {...props} />}
                      isInputNum={true}
                      shouldAutoFocus={true}
                    />
                    <div className="otp-submit-section mx-2">
                      <div className="submit__button"
                        onClick={() => verifyOtpChangeHandler("email")}
                        disabled={validateLoading && submitType === "email"}
                      >
                        {t("submit")}
                        {validateLoading && submitType === "email" ? <Spinner animation="border" size="sm" /> : ""}
                      </div>
                    </div>
                  </div>
                  {!isEmailCompleted ? (
                    <Countdown
                      date={endEmailTime}
                      zeroPadTime={2}
                      renderer={renderer}
                      onComplete={handleEmailComplete}
                      className="mt-1"
                    />
                  ) : (
                    <div
                      className="btn-resend p-0 cursor-pointer mt-1"
                      onClick={() => {
                        setResendType("email")
                        otpSendHandler("email", () => {
                          setEndEmailTime(Date.now() + 30000);
                          setIsEmailCompleted(false);
                          setEmailOtp("");
                        })
                      }
                      }
                      disabled={resendOtpLoader && resendType === "email"}
                    >
                      {t("resend otp")}
                      {resendOtpLoader && resendType === "email" ? <Spinner animation="border" size="sm" className="mx-2" /> : <></>}
                    </div>
                  )}
                </>
              )}
            </div>
          </Col>
        ) : (
          <></>
        )}
        <Col md={6} sm={12}>
          {data?.type.includes("phone") ? (
            <div>
              <h3 className="otp-section-title">{t("phone number otp")}</h3>
              {verifiedPhoneOtp ? (
                <p>{t("phone verification successful")}</p>
              ) : (
                <>
                  <p className="otp-section-subtitle">
                    {t("we have sent an otp to your phone number")}
                  </p>
                  <div className="otp_inputs d-flex align-items-center">
                    <OTPInput
                      value={phoneOtp}
                      onChange={(e) => setPhoneOtp(e)}
                      numInputs={4}
                      className="otp"
                      inputType="text"
                      renderInput={(props) => <input {...props} />}
                      isInputNum={true}
                      shouldAutoFocus={true}
                    />
                    <div className="otp-submit-section mx-2">
                      <div className="submit__button"
                        onClick={() => verifyOtpChangeHandler("phone")}
                        disabled={validateLoading && submitType === "phone"}
                      >
                        {t("submit")}
                        {validateLoading && submitType === "phone" ? <Spinner animation="border" size="sm" /> : ""}
                      </div>
                    </div>
                  </div>
                  {!isPhoneCompleted ? (
                    <Countdown
                      date={endPhoneTime}
                      zeroPadTime={2}
                      renderer={renderer}
                      onComplete={handlePhoneComplete}
                      className="mt-1"
                    />
                  ) : (
                    <div
                      className="btn-resend p-0 cursor-pointer mt-1"
                      onClick={() => {
                        setResendType("phone")
                        otpSendHandler("phone", () => {
                          setEndPhoneTime(Date.now() + 30000);
                          setIsPhoneCompleted(false);
                          setPhoneOtp("");
                        })
                      }
                      }
                      disabled={resendOtpLoader && resendType === "phone"}
                    >
                      {t("resend otp")}
                      {resendOtpLoader && resendType === "phone" ? <Spinner animation="border" size="sm" className="mx-2" /> : <></>}
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      {/* </div> */}

      {/* <div className="otp-submit-main">
        <button className="back mx-2" onClick={() => setStep(2)}>
          <img src="/assets/img/Group 164.svg" alt="good" width={40} />
          Back
        </button>
        <button
          onClick={() => onSubmitHandler()}
          disabled={!verifiedEmailOtp || !verifiedPhoneOtp}
        >
          Submit
          <img src="/assets/img/Group 164.svg" alt="good" width={40} />
        </button>
      </div> */}

      <div className="modal-navigation">
        <button className="back" onClick={() => setStep(2)}>
          <img src="/assets/img/Group 164.svg" alt="good" />
          {t("Back")}
        </button>
        <button className="next" onClick={onSubmitHandler} disabled={!verifiedEmailOtp || !verifiedPhoneOtp || submitLoading}>
          {t("submit")}
          {submitLoading ?
            <div style={{ width: 50 }}>
              <Spinner animation="border" size="sm" />
            </div>
            : <img src="/assets/img/Group 164.svg" alt="good" />}
        </button>
      </div>
    </>
  );
}
