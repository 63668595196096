"use client";

import { useState } from "react";
import { X, ArrowRight, ArrowLeft } from "lucide-react";
import "./PersonalDetails.scss";
import { sendOTP } from "../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { send_otp_loader, updateRedux } from "../../redux/commonReducer";
import { Form, Spinner } from "react-bootstrap";
import { t } from "i18next";

export default function PersonalDetails({
  step,
  setStep,
  onChangeHandler,
  data,
  onTypeChangeHandler,
  err,
  setErr,
  emailOtpClear,
  phoneOtpClear,
}) {
  const dispatch = useDispatch();

  const submitLoading = useSelector(send_otp_loader)

  const handleBack = () => {
    setStep(1);
  };

  const handleNext = () => {
    // setStep(3);
  };

  const validate = () => {
    let temp = {};
    if (!data?.first_name?.trim()?.length) {
      temp = {
        ...temp,
        first_name: t("first name is required"),
      };
    }
    if (!data?.last_name?.trim()?.length) {
      temp = {
        ...temp,
        last_name: t("last name is required"),
      };
    }
    if (!data?.email?.trim()?.length) {
      temp = {
        ...temp,
        email: t("Email is required"),
      };
    } else {
      let regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
      if (!regex.test(data?.email.trim())) {
        temp = {
          ...temp,
          email: t("Invalid email address"),
        };
      }
    }
    if (!data?.phone?.trim()?.length) {
      temp = {
        ...temp,
        phone: t("Phone Number is required"),
      };
    } else if (!/^\d{9}$/.test(data?.phone?.trim())) {
      temp = {
        ...temp,
        phone: t("valid number"),
      };
    }
    if (data?.type?.length == 0) {
      temp = {
        ...temp,
        type: t("mode is required"),
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const submitHandler = () => {
    if (validate()) {
      submitDataHandler(() => {
        setStep(3);
        emailOtpClear();
        phoneOtpClear();
      });
    }
  };

  const submitDataHandler = (cb) => {
    let raw = {
      // first_name: data?.first_name,
      // last_name: data?.last_name,
      phone: data?.phone,
      email: data?.email,
      type: data?.type,
    };
    dispatch(
      sendOTP(raw, () => {
        cb();
      })
    );
  };

  return (
    <>
      <div className="modal-form">
        <div className="modal-form-grid">
          <div className="modal-form-field">
            <input
              type="text"
              name="first_name"
              placeholder={t("your first name")}
              value={data?.first_name}
              onChange={(e) => onChangeHandler("first_name", e.target.value)}
              required
            />
            <span className="text-danger">{err?.first_name}</span>
          </div>

          <div className="modal-form-field">
            <input
              type="text"
              name="last_name"
              placeholder={t("your last name")}
              value={data?.last_name}
              onChange={(e) => onChangeHandler("last_name", e.target.value)}
            />
            <span className="text-danger">{err?.last_name}</span>
          </div>
        </div>

        <div className="modal-form-grid">
          <div className="modal-form-field">
            <input
              type="email"
              name="email"
              placeholder={t("email")}
              value={data?.email}
              onChange={(e) => onChangeHandler("email", e.target.value)}
              required
            />
            <span className="text-danger">{err?.email}</span>
          </div>

          <div className="modal-form-field">
            <div className="modal-form-phone">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    +971
                  </span>
                </div>
                <input
                  type="tel"
                  name="phone"
                  placeholder={t("phone number")}
                  value={data?.phone}
                  onChange={(e) => onChangeHandler("phone", e.target.value)}
                  required
                />
              </div>
            </div>
            <span className="text-danger">{err?.phone}</span>
          </div>
        </div>

        <div className="modal-form-contact">
          <span className="contact-label" style={{ color: "black" }}>
            {t("mode of contact?")}
          </span>
          <div className="checkbox-group">
            <label
              className="checkbox-label"
              for="email"
              style={{ color: "black" }}
            >
              {t("email")}
              <Form.Check // prettier-ignore
                type={"checkbox"}
                id={`default-checkbox-email`}
                name="contact-email"
                label={``}
                className="type__checkbox"
                checked={data?.type.includes("email")}
                onChange={(e) => onTypeChangeHandler("email", e.target.checked)}
              />
            </label>
            <label
              className="checkbox-label"
              for="phone"
              style={{ color: "black" }}
            >
              {t("phone")}
              <Form.Check // prettier-ignore
                type={"checkbox"}
                id={`default-checkbox`}
                name="contact-phone"
                className="type__checkbox"
                label={``}
                checked={data?.type.includes("phone")}
                onChange={(e) => onTypeChangeHandler("phone", e.target.checked)}
              />
            </label>
          </div>
          <span className="text-danger">{err?.type}</span>
        </div>
      </div>
      <div className="modal-navigation">
        <button className="back" onClick={handleBack}>
          <img src="/assets/img/Group 164.svg" alt="good" />
          {t("back")}
        </button>
        <button className="next" onClick={submitHandler}
          disabled={submitLoading}
        >
          {t("next")}
          {submitLoading ?
            <div style={{ width: 50 }}>
              <Spinner animation="border" size="sm" />
            </div>
            : <img src="/assets/img/Group 164.svg" alt="good" />}
        </button>
      </div>
    </>
  );
}
