import { ArrowRight } from 'lucide-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Confirmation.scss'
import { t } from "i18next";

const Confirmation = ({ onClose }) => {
  const navigate = useNavigate()
  const handleClick = () => {
    onClose()
    navigate("/")
  }
  return (
    <div className='confirmation_section'>
      <img src="/assets/img/tick-circle-svgrepo-com.svg" alt="response" />
      <h4>{t("thank you")}</h4>
      <p>{t("We will send you a payment link to the registered email address and phone number shortly!")}</p>

      <div className="back_home">
        <button onClick={handleClick}>
          {t("back to home")}
          <img src="/assets/img/Group 164.svg" alt="good" />
        </button>
      </div>
    </div>
  )
}

export default Confirmation