import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Modal, Navbar, Row, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import logo from "../../assets/images/logo.svg";
import searchIcon from "../../assets/images/search_icon.svg";
import menuIcon from "../../assets/images/menu_icon.svg";
import menuIconWhite from "../../assets/images/logo_white.svg";
import closeIcon from "../../assets/images/close.svg";
import Xlogo from "../../assets/images/x.svg";
import fblogo from "../../assets/images/fb.svg";
import insta from "../../assets/images/insta.svg";
import youtubeLogo from "../../assets/images/youtube.svg";
import linkedinLogo from "../../assets/images/test11.svg";
import icon from "../../assets/images/right_arrow_yellow.svg";
import "./Header.scss";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import RoundedButton from "../../commonComponents/RoundedButton/RoundedButton";
import { useTranslation } from "react-i18next";
import { complaintsform_loading, contact_details, featuredNews, generalData, logo_ar, logo_en } from "../../redux/commonReducer";
import { useDispatch, useSelector } from "react-redux";
import { complaintsForm, getFeaturedNews } from "../../redux/actionCreator";
import TitleBar from "../TitleBar/TitleBar";
import { toast } from "react-toastify";

export const Header = () => {
  const [scroll, setScroll] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuSearch, setSearchOpen] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [lang, setLang] = useState('')
  const { t, i18n } = useTranslation();

  const navigate = useNavigate()
  const location = useLocation()
  // SELECTORS
  const general_data = useSelector(generalData)
  const logo_arabic = useSelector(logo_ar)
  const logo_english = useSelector(logo_en)
  const contactData = useSelector(contact_details)
  const featured_News = useSelector(featuredNews)
  const complaintsSubmitLoading = useSelector(complaintsform_loading)

  // LANGUAGE
  const langSwitch = i18n?.language === 'en'
  const defaultLang = localStorage.getItem('localLang')

  const ref = useRef()
  const fileInputRef = useRef();
  const emiratesIdnputRef = useRef();
  const dispatch = useDispatch()

  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image" || name === "emirates_id") {
      if (files?.length) {
        setData({
          ...data,
          [name]: files[0],
        });
      }
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }

    setErrors({ ...errors, [name]: "" })

  };

  const validation = async () => {
    let isValid = true;
    let errors = {}

    if (!data?.name?.trim()) {
      errors.name = t('first name is required');
    }

    if (!data?.last_name?.trim()) {
      errors.last_name = t('last Name is required');
    }

    if (!data?.image) {
      errors.image = t('attachment is required');
    }

    if (!data?.subject) {
      errors.subject = t('subject is required');
    }

    if (!data?.summary) {
      errors.summary = t('summary is required');
    }

    if (!data?.nationality) {
      errors.nationality = t('nationality is required');
    }

    if (!data?.emirates_id) {
      errors.emirates_id = t('emirates id is required');
    }

    if (!data?.email?.trim()) {
      errors.email = t('Email is required');
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
      errors.email = t('Invalid email address');
    }
    if (!data?.phone?.trim()) {
      errors.phone = t('Phone Number is required');
    } else if (!/^\+?\d{9,13}$/.test(data.phone)) {
      errors.phone = t('valid number');
    }

    if (Object.keys(errors).length) {
      isValid = false
    }

    setErrors(errors)
    return isValid
  }

  const handleSubmit = async () => {
    const isValidated = await validation()
    if (!isValidated) {
      return
    } else {
      console.log({ data })
      let formData = new FormData();
      formData.append('name', data?.name);
      formData.append('last_name', data?.last_name);
      formData.append('image', data?.image);
      formData.append('type', 'general');
      formData.append('phone', data?.phone);
      formData.append('email', data?.email);
      formData.append('subject', data?.subject);
      formData.append('summary', data?.summary);
      formData.append('nationality', data?.nationality);
      formData.append('emirates_id', data?.emirates_id);

      dispatch(complaintsForm(formData, (res) => {
        toast.success("success", {
          position: "bottom-center",
          autoClose: 3000
        })
        setData({
          name: '',
          last_name: "",
          email: '',
          phone: '',
          agree: '',
          image: '',
          subject: '',
          summary: '',
          nationality: '',
          emirates_id: ''
        });
      }))
    }
  }



  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };


  useEffect(() => {
    if (i18n?.language == 'ar' || defaultLang == 'en-US') {
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
    }
  }, [langSwitch])



  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const handleMenu = (e) => {
    if (e == "search") {
      setSearchOpen(!menuSearch);
      setMenuOpen(false);
    } else if (e == "menu") {
      setMenuOpen(!menuOpen);
      setSearchOpen(false);
    }
  };

  const handleCloseMenu = () => {
    setSearchOpen(false);
    setMenuOpen(false);
  };

  const handleSearch = (e) => {
    navigate('/search', {
      state: {
        key: keyword
      }
    })
    setSearchOpen(false);
    setMenuOpen(false);
  }


  useEffect(() => {
    if (!keyword == '') {
      const keyDownHandler = event => {
        if (event.key === 'Enter') {
          event.preventDefault();
          handleSearch();
        }
      };
      document.addEventListener('keydown', keyDownHandler);
      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    }

  }, [keyword]);




  return (
    <>
      <Container>
        <Navbar className={(scroll ? "main__header scrolled" : "main__header nav-fixed") + (location?.pathname == '/' ? ' bg-white' : '')}>
          <Container>
            <Navbar.Brand>
              <Link to="/">
                {
                  !logo_english == '' && !logo_arabic == '' ? <img
                    alt=""
                    src={langSwitch ? logo_english : logo_arabic}
                    className="d-inline-block align-top logo-image"
                  /> : <img
                    alt=""
                    src={logo}
                    className="d-inline-block align-top logo-image"
                  />
                }

              </Link>
            </Navbar.Brand>
            <Navbar.Text className="d-flex align-center justify-center text-align-center">
              <ul className="language__switch">
                <li className="sm-none contact">
                  <Link to="/volunteer_with_us">{t("volunteer with us")}</Link>
                </li>
                <li className="sm-none contact" onClick={() => setModalShow(true)}>
                  <Link >{t("complaints")}</Link>
                </li>
                <li className="sm-none contact">
                  <Link to="/enquiry">{t("enquiry")}</Link>
                </li>
                <li className="sm-none contact">
                  <Link to="/contact">{t("contact us")}</Link>
                </li>
                <li className={langSwitch && 'active'} onClick={() => changeLanguage("en")}>EN</li>
                <li className={!langSwitch && 'active'} onClick={() => changeLanguage("ar")}>AR</li>
              </ul>
              <img
                alt=""
                src={searchIcon}
                onClick={() => handleMenu("search")}
                height="18"
                className="mr-3 ml-3 align-self-center pointer icon__header"
              />
              <img
                alt=""
                src={menuIcon}
                height="18"
                className="align-self-center pointer icon__header"
                onClick={() => handleMenu("menu")}
              />
            </Navbar.Text>
          </Container>
        </Navbar>
      </Container>
      {(menuOpen || menuSearch) && (
        <div
          className={menuOpen ? "menu__items open" : "menu__items"}
          data-aos="fade-down"
        >
          <div className="header__logo">
            <div className="logo">
              <img src={menuIconWhite} alt="" />
            </div>
            <div
              className="closeMenu pointer"
              onClick={() => handleCloseMenu()}
            >
              <img src={closeIcon} alt="" />
              <h6>{t("close")}</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div
                className="menu__links"
              >
                {menuSearch ? (
                  <div className="search__box">
                    <h2>{t("search here")}</h2>
                    <p>{langSwitch ? general_data?.search_title : general_data?.search_title_ar}</p>
                    <input type="text" placeholder={t('search here')} className="form-control" onChange={(e) => setKeyword(e.target.value)} />
                    <div className="text-right">
                      <RoundedButton noIcon title={t('search')} varient={'primary'} onClick={handleSearch} />
                    </div>
                  </div>
                ) : (
                  <>
                    <ul data-aos="fade-zoom-in" data-aos-duration="1000"
                      onClick={() => handleCloseMenu()}
                    >
                      <li>
                        <span></span>
                        <NavLink to="/">{t("home")}</NavLink>
                      </li>
                      <li>
                        <span></span>
                        <NavLink to="/about">{t("about us")}</NavLink>
                      </li>
                      <li>
                        <span></span>
                        <NavLink to="/what-we-do">{t("what we do")}</NavLink>
                      </li>
                      {
                        featured_News?.length > 0 &&
                        <li>
                          <span></span>
                          <NavLink to={"/News"}>{t("news")}</NavLink>
                        </li>
                      }

                      <li>
                        <span></span>
                        <NavLink to="/local-events">{t("local events")}</NavLink>
                      </li>
                      <li>
                        <span></span>
                        <NavLink to="/Events">{t("international events")}</NavLink>
                      </li>

                    </ul>
                    <ul
                      data-aos="fade-zoom-in"
                      data-aos-duration="2000"
                      onClick={() => handleCloseMenu()}
                    >
                      <li>
                        <span></span>
                        <NavLink to="/Research">{t("research")}</NavLink>
                      </li>
                      <li>
                        <span></span>
                        <NavLink to={`/library/gallery`}>{t("gallery")}</NavLink>
                      </li>
                      <li>
                        <span></span>
                        <NavLink to={`/library/reports`}>{t("report")}</NavLink>
                      </li>
                      <li>
                        <span data-hide-zero={featured_News?.length > 0 ? "true" : "false"}></span>
                        <NavLink to={`/library/awareness`}>{t("awareness")}</NavLink>
                      </li>
                      <li>
                        <span data-hide-zero="true"></span>
                        <NavLink to="/Faq">{t("faq")}</NavLink>
                      </li>
                      <li>
                        <span data-hide-zero="true"></span>
                        <NavLink to="/Contact">{t("contact us")}</NavLink>
                      </li>
                    </ul>
                  </>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="menu__contact">
                <div
                  className="contact__details nav-fade"
                >
                  <h3>{t("address")}</h3>
                  <p>{langSwitch ? contactData?.address : contactData?.address_ar} </p>
                </div>
                <div
                  className="contact__details nav-fade"
                >
                  <h3>{t("email")}</h3>
                  <p><a href={`mailto:${contactData?.email}`}>{contactData?.email}</a></p>
                </div>
                <div
                  className="contact__details nav-fade"
                >
                  <h3>{t("phone")}</h3>
                  <p className="phoneRtl"><a href={`tel:${contactData?.telephone}`}>{contactData?.telephone}</a></p>
                </div>
                <div
                  className="contact__details nav-fade"
                >
                  {
                    (general_data?.facebook ||
                      general_data?.twitter ||
                      general_data?.youtube ||
                      general_data?.instagram ||
                      general_data?.linkedln) && <h3>{t("follow us")}</h3>
                  }

                  <ul>
                    {general_data?.facebook && <li> <a href={general_data?.facebook} target="_blank"> <img src={fblogo} alt="" /> </a> </li>}
                    {general_data?.twitter && <li> <a href={general_data?.twitter} target="_blank"> <img src={Xlogo} alt="" /> </a> </li>}
                    {general_data?.youtube && <li> <a href={general_data?.youtube} target="_blank"> <img src={youtubeLogo} alt="" /> </a> </li>}
                    {general_data?.instagram && <li> <a href={general_data?.instagram} target="_blank"> <img src={insta} alt="" /> </a> </li>}
                    {general_data?.linkedln && <li> <a href={general_data?.linkedln} target="_blank"> <img src={linkedinLogo} alt="" /> </a> </li>}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* MODAL */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Body>
          <div className="member__modal complaints__modal">
            <div
              className="modal-close"
              onClick={() => setModalShow(false)}
            >
              <img src="/assets/img/icons/modal-close.svg" alt="" />
            </div>

            <TitleBar
              title={t('complaints')}
              noBtn
            />
            <Row>
              <Col md={12}>
                <div className="form-group">
                  <input type="text" name="subject" value={data?.subject} ref={ref} className="form-control" placeholder={t('subject')} onChange={handleInputChange} />
                  <p className="error">{errors?.subject}</p>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <input type="text" name="name" value={data?.name} ref={ref} className="form-control" placeholder={t('your first name')} onChange={handleInputChange} />
                  <p className="error">{errors?.name}</p>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <input type="text" name="last_name" value={data?.last_name} ref={ref} className="form-control" placeholder={t('your last name')} onChange={handleInputChange} />
                  <p className="error">{errors?.last_name}</p>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <input type="text" name="email" value={data?.email} ref={ref} className="form-control" placeholder={t('email')} onChange={handleInputChange} />
                  <p className="error">{errors?.email}</p>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group position-relative">
                  <p className="position-absolute country__code">+971</p>
                  <input type="number" name="phone" value={data?.phone} ref={ref} className="form-control phone__number" placeholder={t('phone number')} onChange={handleInputChange} />
                  <p className="error position-absolute">{errors?.phone}</p>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  {/* <select name="nationality" value={data?.nationality} ref={ref} className="form-control select__country" onChange={handleInputChange} placeholder="select">
                    <option value={""}>Nationality*</option>
                    <option value={"india"}>India</option>
                    <option value={"uae"}>UAE</option>
                  </select> */}
                  <input type="text" name="nationality" value={data?.nationality} ref={ref} className="form-control" placeholder={t('nationality')} onChange={handleInputChange} />
                  <p className="error">{errors?.nationality}</p>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <input name="emirates_id" onChange={handleInputChange} multiple={false} ref={emiratesIdnputRef} type='file' hidden />
                  <div className="form-control custom__upload cursor-pointer" onClick={() => {
                    if (data?.emirates_id) {
                      const fileUrl = data?.emirates_id && URL.createObjectURL(data?.emirates_id);
                      data?.emirates_id && window.open(fileUrl, '_blank');
                    } else
                      emiratesIdnputRef.current.click()
                  }}>
                    <label for="files">{data?.emirates_id ? data?.emirates_id?.name : t("emirates id")}</label>
                    <div className="upload__text" onClick={() => emiratesIdnputRef.current.click()}>
                      {t("upload")}
                    </div>
                  </div>
                  <p className="error">{errors?.emirates_id}</p>
                </div>
              </Col>
              <Col md={12}>
                <div className="form-group">
                  <textarea style={{ height: 80 }} type="text" name="summary" value={data?.summary} ref={ref} className="form-control" placeholder={t('summary')} onChange={handleInputChange} />
                  <p className="error">{errors?.summary}</p>
                </div>
              </Col>
              <Col md={12} className="position-relative">
                <div className="form-group upload__cv" onClick={() => fileInputRef.current.click()}>
                  <input name="image" onChange={handleInputChange} multiple={false} ref={fileInputRef} type='file' hidden />
                  <div>
                    <img width={30} src={'./assets/img/upload-cloud.svg'} />
                    <label className="mx-3 upload_cv_text" onClick={() => {
                      const fileUrl = data?.image && URL.createObjectURL(data?.image);
                      data?.image && window.open(fileUrl, '_blank');
                    }}>{data?.image ? data?.image?.name : t("upload attachment")}</label>
                  </div>
                  <p className="supporting_documents">{t("supporting documents ( PDF, JPG, PNG )")}</p>
                </div>
                <p className="error position-absolute">{errors?.image}</p>
              </Col>
            </Row>
            <Row className="mt-4 mb-4">
              <Col md={12} sm={12} className="d-flex align-items-end justify-content-end">
                <button disabled={complaintsSubmitLoading} className="submit" onClick={handleSubmit}>
                  {t('submit')}
                  {!complaintsSubmitLoading ? <img src={icon} alt="" width={35} className="mx-2" /> :
                    <Spinner className="mx-2" animation="border" width={20} size="sm" />}
                </button>
              </Col>
            </Row>


          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
