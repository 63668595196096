import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import TitleBar from "../components/TitleBar/TitleBar";
import { useDispatch, useSelector } from "react-redux";
import { contactEnquiry, contactVolunteerForm, generalEnquiryForm } from "../redux/actionCreator";
import { toast } from "react-toastify";
import i18n from "../i18n";
import { contact_details, contact_loader, enquiryform_loading } from "../redux/commonReducer";
import { t } from "i18next";
import { Link } from "react-router-dom";
import icon from "../assets/images/right_arrow_yellow.svg";

function Enquiry() {

    const ref = useRef()
    const fileInputRef = useRef();
    const dispatch = useDispatch()

    const loading = useSelector(enquiryform_loading)

    const [errors, setErrors] = useState({});
    const [data, setData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "image") {
            if (files?.length) {
                setData({
                    ...data,
                    [name]: files[0],
                });
            }
        } else {
            setData({
                ...data,
                [name]: value,
            });
        }

        setErrors({ ...errors, [name]: "" })

    };

    const validation = async () => {
        let isValid = true;
        let errors = {}

        if (!data?.name?.trim()) {
            errors.name = t('first name is required');
        }

        if (!data?.last_name?.trim()) {
            errors.last_name = t('last name is required');
        }

        if (!data?.image) {
            errors.image = t('attachment is required');
        }

        if (!data?.subject) {
            errors.subject = t('subject is required');
        }

        if (!data?.summary) {
            errors.summary = t('summary is required');
        }

        if (!data?.email?.trim()) {
            errors.email = t('Email is required');
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
            errors.email = t('Invalid email address');
        }
        if (!data?.phone?.trim()) {
            errors.phone = t('Phone Number is required');
        } else if (!/^\+?\d{9,13}$/.test(data.phone)) {
            errors.phone = t('valid number');
        }

        if(Object.keys(errors).length){
            isValid = false
        }

        setErrors(errors)
        return isValid
    }

    const handleSubmit = async () => {
        const isValidated = await validation()
        if (!isValidated) {
            return
        } else {
            console.log({ data })
            let formData = new FormData();
            formData.append('name', data?.name);
            formData.append('last_name', data?.last_name);
            formData.append('image', data?.image);
            formData.append('type', 'general');
            formData.append('phone', data?.phone);
            formData.append('email', data?.email);
            formData.append('subject', data?.subject);
            formData.append('summary', data?.summary);

            dispatch(generalEnquiryForm(formData, (res) => {
                toast.success(res?.data?.message, {
                    position: "bottom-center",
                    autoClose: 3000
                })
                setData({
                    name: '',
                    last_name: "",
                    email: '',
                    phone: '',
                    agree: '',
                    image: '',
                    subject: '',
                    summary: ''
                });
            }))
        }
    }

    return (
        <section className="volunteer_with_us">
            <Container>
                <TitleBar
                    title={t('general enquiry')}
                    breadcrumbs={[
                        { name: `${t('home')}`, path: "/" },
                        { name: `${t('general enquiry')}`, path: "/enquiry" },
                    ]}
                    noBtn
                />
                <Row>
                    <Col md={6}>
                        <div className="form-group">
                            <input type="text" name="name" value={data?.name} ref={ref} className="form-control" placeholder={t('your first name')} onChange={handleInputChange} />
                            <p className="error">{errors?.name}</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="form-group">
                            <input type="text" name="last_name" value={data?.last_name} ref={ref} className="form-control" placeholder={t('your last name')} onChange={handleInputChange} />
                            <p className="error">{errors?.last_name}</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="form-group">
                            <input type="text" name="email" value={data?.email} ref={ref} className="form-control" placeholder={t('email')} onChange={handleInputChange} />
                            <p className="error">{errors?.email}</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="form-group position-relative">
                            <p className="position-absolute country__code">+971</p>
                            <input type="number" name="phone" value={data?.phone} ref={ref} className="form-control phone__number" placeholder={t('phone number')} onChange={handleInputChange} />
                            <p className="error position-absolute">{errors?.phone}</p>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="form-group">
                            <input type="text" name="subject" value={data?.subject} ref={ref} className="form-control" placeholder={t('subject')} onChange={handleInputChange} />
                            <p className="error">{errors?.subject}</p>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="form-group">
                            <textarea style={{ height: 100 }} type="text" name="summary" value={data?.summary} ref={ref} className="form-control" placeholder={t('summary')} onChange={handleInputChange} />
                            <p className="error">{errors?.summary}</p>
                        </div>
                    </Col>
                    <Col md={12} className="position-relative">
                        <div className="form-group upload__cv" onClick={() => fileInputRef.current.click()}>
                            <input name="image" onChange={handleInputChange} multiple={false} ref={fileInputRef} type='file' hidden />
                            <div>
                                <img width={30} src={'./assets/img/upload-cloud.svg'} />
                                <label className="mx-3 upload_cv_text" onClick={() => {
                                    const fileUrl = data?.image && URL.createObjectURL(data?.image);
                                    data?.image && window.open(fileUrl, '_blank');
                                }
                                }>{data?.image ? data?.image?.name : "Upload Attachment"}</label>
                            </div>
                            <p className="supporting_documents">Supporting Documents ( PDF )</p>
                        </div>
                        <p className="error position-absolute">{errors?.image}</p>
                    </Col>
                </Row>
                <Row className="mt-4 mb-4">
                    <Col md={12} sm={12} className="d-flex align-items-end justify-content-end">
                        <button disabled={loading} className="submit" onClick={handleSubmit}>
                            {t('submit')}
                            {!loading ? <img src={icon} alt="" width={35} className="mx-2" /> :
                                <Spinner className="mx-2" animation="border" width={20} size="sm" />}
                        </button>
                    </Col>
                </Row>
            </Container>
            <img src="../assets/img/volunteer.png" width={"100%"} />
        </section>
    );
}

export default Enquiry;
